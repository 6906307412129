import { TicketType } from '@gain/rpc/app-model'
import Dialog from '@mui/material/Dialog'

import RequestProfileForm from './request-profile-form'

export interface RequestProfileDialogProps {
  initialProfileType: TicketType
  initialCompanyName?: string
  initialCompanyWebsite?: string
  open: boolean
  onClose: () => void
}

export default function RequestProfileDialog({
  initialProfileType,
  initialCompanyName,
  initialCompanyWebsite,
  open,
  onClose,
}: RequestProfileDialogProps) {
  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      fullWidth>
      {/* The form is in a component so that it is unmounted when the dialog closes,
          otherwise the form state is retained. */}
      <RequestProfileForm
        initialCompanyName={initialCompanyName}
        initialCompanyWebsite={initialCompanyWebsite}
        initialProfileType={initialProfileType}
        onClose={onClose}
      />
    </Dialog>
  )
}
