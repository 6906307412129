import { objectKeys } from '@gain/utils/typescript'

export enum RpcErrorCode {
  InvalidToken = -32001,
  InvalidCredentials = -32002,
  Unauthorized = -32003,
  InvalidResourceIdentifier = -32004,
  ResourceOffline = -32007,
  ValidationFailed = -32008,
  ParseGermanFinancialsFailed = -32011,
  ExportLimitReached = -32011,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function serializeError(error: any) {
  if (error instanceof RpcError) {
    return {
      name: error.name,
      code: error.code,
      message: error.message,
      data: error.data,
    }
  } else if (error instanceof Error) {
    return {
      name: error.name,
      message: error.message,
    }
  }
  return error
}

const rpcErrorCodesAsString = objectKeys(RpcErrorCode).map((key) => RpcErrorCode[key].toString())

interface SerializedRpcError {
  code: string
  message: string
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const isSerializedJsonRpcError = (error: any): error is SerializedRpcError =>
  typeof error === 'object' &&
  'code' in error &&
  typeof error.code === 'string' &&
  rpcErrorCodesAsString.includes(error.code) &&
  'message' in error &&
  typeof error.message === 'string'

export const deserializeJsonRpcError = (error: SerializedRpcError) =>
  new RpcError(error.message, parseInt(error.code, 10) as RpcErrorCode)

/* eslint-disable @typescript-eslint/no-explicit-any */
export class RpcError<Data = any> extends Error {
  constructor(
    override readonly message: string,
    readonly code: RpcErrorCode,
    readonly data?: Data
  ) {
    super(message)
  }
}

export class UnexpectedRpcError extends Error {
  constructor(
    message: string,
    readonly status: number,
    readonly response?: string,
    readonly data?: { url: string }
  ) {
    super(`Unknown RPC error: ${message}`)
  }
}

export function isUnexpectedRpcError(error: Error | unknown): error is UnexpectedRpcError {
  return error instanceof UnexpectedRpcError
}

export function isNotAuthorized(code: RpcErrorCode): boolean {
  return code === RpcErrorCode.InvalidToken
}

export function isInvalidResource(code: RpcErrorCode): boolean {
  return code === RpcErrorCode.InvalidResourceIdentifier
}

export function isOffLineResource(code: RpcErrorCode): boolean {
  return code === RpcErrorCode.ResourceOffline
}

export function isParsingOfFinancials(code: RpcErrorCode): boolean {
  return code === RpcErrorCode.ParseGermanFinancialsFailed
}

export function isValidationFailed(code: RpcErrorCode): boolean {
  return code === RpcErrorCode.ValidationFailed
}
