import { TicketType } from '@gain/rpc/app-model'

import {
  getRequestAdvisorProfileParams,
  RequestAdvisorProfileFields,
  RequestAdvisorProfileFormSchema,
} from './request-advisor-profile-form'
import {
  getRequestCompanyProfileParams,
  RequestCompanyProfileFields,
  RequestCompanyProfileFormSchema,
} from './request-company-profile-form'
import {
  getRequestConferenceProfileParams,
  RequestConferenceProfileFields,
  RequestConferenceProfileFormSchema,
} from './request-conference-profile-form'
import {
  getRequestDealProfileParams,
  RequestDealProfileFields,
  RequestDealProfileFormSchema,
} from './request-deal-profile-form'
import {
  getRequestInvestorProfileParams,
  RequestInvestorProfileFields,
  RequestInvestorProfileFormSchema,
} from './request-investor-profile-form'

export interface RequestProfileFormValues {
  companyName: string
  companyWebsite: string
  investorName: string
  investorWebsite: string
  advisorName: string
  advisorWebsite: string
  conferenceName: string
  conferenceWebsite: string
  dealTargetName: string
  dealTargetWebsite: string
  dealBuyers: string
  dealSellers: string
  dealDate: string
  comment: string
}

export function createInitialValues(initialValues: {
  initialCompanyName?: string
  initialCompanyWebsite?: string
}): RequestProfileFormValues {
  return {
    companyName: initialValues.initialCompanyName || '',
    companyWebsite: initialValues.initialCompanyWebsite || '',
    investorName: '',
    investorWebsite: '',
    advisorName: '',
    advisorWebsite: '',
    conferenceName: '',
    conferenceWebsite: '',
    dealTargetName: '',
    dealTargetWebsite: '',
    dealBuyers: '',
    dealSellers: '',
    dealDate: '',
    comment: '',
  }
}

export const FormSchemaMap = {
  [TicketType.RequestCompanyProfile]: RequestCompanyProfileFormSchema,
  [TicketType.RequestInvestorProfile]: RequestInvestorProfileFormSchema,
  [TicketType.RequestAdvisorProfile]: RequestAdvisorProfileFormSchema,
  [TicketType.RequestConferenceProfile]: RequestConferenceProfileFormSchema,
  [TicketType.RequestDealProfile]: RequestDealProfileFormSchema,
}

export const FormParamsGetterMap = {
  [TicketType.RequestCompanyProfile]: getRequestCompanyProfileParams,
  [TicketType.RequestInvestorProfile]: getRequestInvestorProfileParams,
  [TicketType.RequestAdvisorProfile]: getRequestAdvisorProfileParams,
  [TicketType.RequestConferenceProfile]: getRequestConferenceProfileParams,
  [TicketType.RequestDealProfile]: getRequestDealProfileParams,
}

export interface RequestProfileFieldsProps {
  profileType: TicketType
}

export function RequestProfileFields({ profileType }: RequestProfileFieldsProps) {
  return (
    <>
      {profileType === TicketType.RequestCompanyProfile && <RequestCompanyProfileFields />}
      {profileType === TicketType.RequestInvestorProfile && <RequestInvestorProfileFields />}
      {profileType === TicketType.RequestAdvisorProfile && <RequestAdvisorProfileFields />}
      {profileType === TicketType.RequestConferenceProfile && <RequestConferenceProfileFields />}
      {profileType === TicketType.RequestDealProfile && <RequestDealProfileFields />}
    </>
  )
}
