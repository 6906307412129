import { TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import * as yup from 'yup'

import { TextInput } from '../../common/form'
import { RequestProfileFormValues } from './request-profile-utils'

export const RequestCompanyProfileFormSchema: yup.ObjectSchema<
  Pick<RequestProfileFormValues, 'companyName' | 'companyWebsite' | 'comment'>
> = yup.object({
  companyName: yup.string().defined().required('Company name field is required'),
  companyWebsite: yup.string().defined().required('Company website field is required'),
  comment: yup.string().defined(),
})

export function getRequestCompanyProfileParams(values: RequestProfileFormValues) {
  return {
    type: TicketType.RequestCompanyProfile,
    body: values.comment,
    attributes: [
      { type: TicketAttributeType.CompanyName, value: values.companyName },
      { type: TicketAttributeType.CompanyWebsite, value: values.companyWebsite },
    ],
  }
}

export function RequestCompanyProfileFields() {
  return (
    <>
      <TextInput
        label={'Company name'}
        name={'companyName'}
        placeholder={'Company name'}
      />
      <TextInput
        label={'Company website'}
        name={'companyWebsite'}
        placeholder={'https://www.example.com'}
        type={'url'}
      />
    </>
  )
}
