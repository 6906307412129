import { TicketType } from '@gain/rpc/app-model'
import Alert, { alertClasses } from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import RequestProfileDialog from '../../../request-profile/request-profile-dialog'

const StyledAlert = styled(Alert)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0.5, 2),

  [`& .${alertClasses.message}`]: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
  },
}))

export default function SearchRequestProfile() {
  const [requestProfileDialogOpen, setRequestProfileDialogOpen] = useState(false)

  return (
    <>
      <StyledAlert
        icon={false}
        severity={'info'}>
        <Typography
          variant={'body2'}
          noWrap>
          Didn't find what you were looking for?
        </Typography>
        <Button
          color={'primary'}
          onClick={() => setRequestProfileDialogOpen(true)}
          variant={'text'}>
          Request a profile
        </Button>
      </StyledAlert>

      <RequestProfileDialog
        initialProfileType={TicketType.RequestCompanyProfile}
        onClose={() => setRequestProfileDialogOpen(false)}
        open={requestProfileDialogOpen}
      />
    </>
  )
}
