import { TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import * as yup from 'yup'

import { TextInput } from '../../common/form'
import { RequestProfileFormValues } from './request-profile-utils'

export const RequestConferenceProfileFormSchema: yup.ObjectSchema<
  Pick<RequestProfileFormValues, 'conferenceName' | 'conferenceWebsite' | 'comment'>
> = yup.object({
  conferenceName: yup.string().defined().required('Conference name field is required'),
  conferenceWebsite: yup.string().defined().required('Conference website field is required'),
  comment: yup.string().defined(),
})

export function getRequestConferenceProfileParams(values: RequestProfileFormValues) {
  return {
    type: TicketType.RequestConferenceProfile,
    body: values.comment,
    attributes: [
      { type: TicketAttributeType.ConferenceName, value: values.conferenceName },
      { type: TicketAttributeType.ConferenceWebsite, value: values.conferenceWebsite },
    ],
  }
}

export function RequestConferenceProfileFields() {
  return (
    <>
      <TextInput
        label={'Conference name'}
        name={'conferenceName'}
        placeholder={'Conference name'}
      />
      <TextInput
        label={'Conference website'}
        name={'conferenceWebsite'}
        placeholder={'https://www.example.com'}
        type={'url'}
      />
    </>
  )
}
