import { useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { TicketType } from '@gain/rpc/app-model'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import DialogHeader from '../../common/dialog-header'
import { TextInput } from '../../common/form'
import {
  createInitialValues,
  FormParamsGetterMap,
  FormSchemaMap,
  RequestProfileFields,
  RequestProfileFormValues,
} from './request-profile-utils'

const StyledForm = styled('form')({
  display: 'contents',
})

const SNACKBAR_KEY = 'profile-requested'

export interface RequestProfileFormProps {
  initialProfileType: TicketType
  initialCompanyName?: string
  initialCompanyWebsite?: string
  onClose: () => void
}

export default function RequestProfileForm({
  initialProfileType,
  initialCompanyName,
  initialCompanyWebsite,
  onClose,
}: RequestProfileFormProps) {
  const fetcher = useRpcClient()
  const [error, setError] = useState<null | string>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [profileType, setProfileType] = useState<TicketType>(initialProfileType)

  const form = useForm<RequestProfileFormValues>({
    defaultValues: createInitialValues({
      initialCompanyName,
      initialCompanyWebsite,
    }),
    resolver: yupResolver(FormSchemaMap[profileType]),
  })

  const onSubmit: SubmitHandler<RequestProfileFormValues> = async (values) => {
    setSubmitting(true)
    setError(null)
    try {
      await fetcher({
        method: 'account.createTicket',
        params: FormParamsGetterMap[profileType](values),
      })
      enqueueSnackbar(undefined, {
        key: SNACKBAR_KEY,
        content: () => (
          <Snackbar
            id={SNACKBAR_KEY}
            message={'Your profile request has been received. We will get back to you shortly.'}
          />
        ),
        preventDuplicate: true,
      })
      onClose()
    } catch {
      setError('An error occurred while submitting the form, please try again later.')
    }

    setSubmitting(false)
  }

  return (
    <FormProvider {...form}>
      <StyledForm
        onSubmit={form.handleSubmit(onSubmit)}
        noValidate>
        <DialogHeader
          onCloseClick={onClose}
          title={'Request a profile'}
        />

        <DialogContent>
          <Stack spacing={2}>
            {error && (
              <Alert
                icon={false}
                severity={'error'}>
                {error}
              </Alert>
            )}
            <Alert
              icon={false}
              severity={'info'}>
              Please fill in the form below and we'll get back to you within 3 business days.
            </Alert>
            <FormControl fullWidth>
              <InputLabel htmlFor={'profileType'}>What do you want to request?</InputLabel>
              <Select
                id={'profileType'}
                label={'Profile type'}
                onChange={(e) => {
                  setProfileType(e.target.value as TicketType)
                  form.reset() // unfortunately we need to reset the form so the resolver is updated properly
                }}
                value={profileType}>
                <MenuItem value={TicketType.RequestCompanyProfile}>Company profile</MenuItem>
                <MenuItem value={TicketType.RequestInvestorProfile}>Investor profile</MenuItem>
                <MenuItem value={TicketType.RequestAdvisorProfile}>Advisor profile</MenuItem>
                <MenuItem value={TicketType.RequestConferenceProfile}>Conference profile</MenuItem>
                <MenuItem value={TicketType.RequestDealProfile}>Deal profile</MenuItem>
              </Select>
            </FormControl>
            <RequestProfileFields profileType={profileType} />
            <TextInput
              label={'Comment'}
              maxRows={10}
              minRows={7}
              name={'comment'}
              placeholder={'Anything else you want to share with us?'}
              multiline
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            color={'primary'}
            disabled={!form.formState.isDirty || !form.formState.isValid}
            loading={submitting}
            type={'submit'}
            variant={'contained'}
            fullWidth>
            Send
          </LoadingButton>
        </DialogActions>
      </StyledForm>
    </FormProvider>
  )
}
