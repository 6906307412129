import { TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import * as yup from 'yup'

import { TextInput } from '../../common/form'
import { RequestProfileFormValues } from './request-profile-utils'

export const RequestInvestorProfileFormSchema: yup.ObjectSchema<
  Pick<RequestProfileFormValues, 'investorName' | 'investorWebsite' | 'comment'>
> = yup.object({
  investorName: yup.string().defined().required('Investor name field is required'),
  investorWebsite: yup.string().defined().required('Investor website field is required'),
  comment: yup.string().defined(),
})

export function getRequestInvestorProfileParams(values: RequestProfileFormValues) {
  return {
    type: TicketType.RequestInvestorProfile,
    body: values.comment,
    attributes: [
      { type: TicketAttributeType.InvestorName, value: values.investorName },
      { type: TicketAttributeType.InvestorWebsite, value: values.investorWebsite },
    ],
  }
}

export function RequestInvestorProfileFields() {
  return (
    <>
      <TextInput
        label={'Investor name'}
        name={'investorName'}
        placeholder={'Investor name'}
      />
      <TextInput
        label={'Investor website'}
        name={'investorWebsite'}
        placeholder={'https://www.example.com'}
        type={'url'}
      />
    </>
  )
}
