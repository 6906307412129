import { TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import * as yup from 'yup'

import { TextInput } from '../../common/form'
import { RequestProfileFormValues } from './request-profile-utils'

export const RequestAdvisorProfileFormSchema: yup.ObjectSchema<
  Pick<RequestProfileFormValues, 'advisorName' | 'advisorWebsite' | 'comment'>
> = yup.object({
  advisorName: yup.string().defined().required('Advisor name field is required'),
  advisorWebsite: yup.string().defined().required('Advisor website field is required'),
  comment: yup.string().defined(),
})

export function getRequestAdvisorProfileParams(values: RequestProfileFormValues) {
  return {
    type: TicketType.RequestAdvisorProfile,
    body: values.comment,
    attributes: [
      { type: TicketAttributeType.AdvisorName, value: values.advisorName },
      { type: TicketAttributeType.AdvisorWebsite, value: values.advisorWebsite },
    ],
  }
}

export function RequestAdvisorProfileFields() {
  return (
    <>
      <TextInput
        label={'Advisor name'}
        name={'advisorName'}
        placeholder={'Advisor name'}
      />
      <TextInput
        label={'Advisor website'}
        name={'advisorWebsite'}
        placeholder={'https://www.example.com'}
        type={'url'}
      />
    </>
  )
}
