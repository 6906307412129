import { TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import * as yup from 'yup'

import { TextInput } from '../../common/form'
import { RequestProfileFormValues } from './request-profile-utils'

export const RequestDealProfileFormSchema: yup.ObjectSchema<
  Pick<
    RequestProfileFormValues,
    'dealTargetName' | 'dealTargetWebsite' | 'dealBuyers' | 'dealSellers' | 'dealDate' | 'comment'
  >
> = yup.object({
  dealTargetName: yup.string().defined().required('Deal target name field is required'),
  dealTargetWebsite: yup.string().defined().required('Deal target website field is required'),
  dealBuyers: yup.string().defined(),
  dealSellers: yup.string().defined(),
  dealDate: yup.string().defined(),
  comment: yup.string().defined(),
})

export function getRequestDealProfileParams(values: RequestProfileFormValues) {
  return {
    type: TicketType.RequestDealProfile,
    body: values.comment,
    attributes: [
      { type: TicketAttributeType.DealTargetName, value: values.dealTargetName },
      { type: TicketAttributeType.DealTargetWebsite, value: values.dealTargetWebsite },
      { type: TicketAttributeType.DealBuyers, value: values.dealBuyers },
      { type: TicketAttributeType.DealSellers, value: values.dealSellers },
      { type: TicketAttributeType.DealDate, value: values.dealDate },
    ],
  }
}

export function RequestDealProfileFields() {
  return (
    <>
      <TextInput
        label={'Deal target name'}
        name={'dealTargetName'}
        placeholder={'Deal target name'}
      />
      <TextInput
        label={'Deal target website'}
        name={'dealTargetWebsite'}
        placeholder={'https://www.example.com'}
        type={'url'}
      />
      <TextInput
        label={'Deal buyers'}
        name={'dealBuyers'}
        placeholder={'Deal buyers'}
      />
      <TextInput
        label={'Deal sellers'}
        name={'dealSellers'}
        placeholder={'Deal sellers'}
      />
      <TextInput
        label={'Deal date'}
        name={'dealDate'}
        placeholder={'e.g. MM/YYYY'}
      />
    </>
  )
}
